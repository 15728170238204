<template>
  <v-dialog v-model="showEditDialog" max-width="80%">
    <v-card>
      <v-card-title>Edit Quiz</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="saveQuiz">
          <v-text-field v-model="editedQuiz.title" label="Title"></v-text-field>
          <v-text-field v-model="editedQuiz.date" label="Date" type="date"></v-text-field>
          <v-text-field v-model="editedQuiz.start_time" label="Start Time" type="time"></v-text-field>
          <v-text-field v-model="editedQuiz.end_time" label="End Time" type="time"></v-text-field>
          <div v-for="(question, questionIndex) in editedQuiz.questions" :key="questionIndex">
            <h1>Question {{ questionIndex + 1 }}</h1>
            <br>
            <v-text-field v-model="question.question_text" :label="'Question ' + (questionIndex + 1) + ' Text'"></v-text-field>
            <v-select v-model="question.question_type" :items="questionTypes" :label="'Question ' + (questionIndex + 1) + ' Type'"></v-select>
            <v-text-field v-model="question.mark" :label="'Question ' + (questionIndex + 1) + ' Mark'"></v-text-field>
            <h5>Options:</h5>
            <div v-for="(option, optionIndex) in question.options" :key="optionIndex">
              <v-text-field v-model="option.option_text" :label="'Option ' + (optionIndex + 1) + ' Text'"></v-text-field>
              <v-checkbox v-model="option.is_correct" :label="'Correct ' + (optionIndex + 1)"></v-checkbox>
              <v-btn color="error" @click="removeOption(questionIndex, optionIndex)">Remove Option</v-btn>
            </div>
            <v-btn color="primary" @click="addOption(questionIndex)">Add Option</v-btn>
            <br><br>
            <v-btn color="error" @click="removeQuestion(questionIndex)">Remove Question</v-btn>
            <v-btn color="primary" @click="addQuestion">Add Question</v-btn>

          </div>
          <v-card-actions>
            <v-btn color="primary" type="submit">Edit</v-btn>
            <v-btn color="error" @click="cancelEdit">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    selectedQuiz: {
      type: Object,
      required: true,
    },
    showEditDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editedQuiz: {},
      questionTypes: [
        { text: "Checkbox for Single", value: "Checkbox for Single" },
        { text: "Checkbox for Multiple", value: "Checkbox for Multiple" },
        { text: "Dropdown", value: "Dropdown" },
        { text: "Text", value: "Text" },
        { text: "File", value: "File" },
      ],
    };
  },
  watch: {
    selectedQuiz: {
      immediate: true,
      handler() {
        this.editedQuiz = { ...this.selectedQuiz };
      },
    },
  },
  methods: {
    removeQuestion(index) {
  const question = this.editedQuiz.questions[index];
  const questionId = question.id;

  if (questionId) {
    this.$api.delete(`/questions/${questionId}/`)
      .then(() => {
        this.editedQuiz.questions.splice(index, 1);
      })
      .catch((error) => {
        console.error('Failed to delete question:', error);
      });
  } else {
    this.editedQuiz.questions.splice(index, 1);
  }
},
addQuestion() {
  const newQuestion = {
    question_text: "",
    question_type: "", 
    mark: 0, 
    options: [] 
  };

  this.editedQuiz.questions.push(newQuestion);

  this.$api.post('/questions/', newQuestion)
    .then((response) => {
      const createdQuestion = response.data;
      this.editedQuiz.questions.push(createdQuestion);
    })
    .catch((error) => {
      console.error('Failed to create question:', error);
    });
},


addOption(questionIndex) {
  const question = this.editedQuiz.questions[questionIndex];
  const questionId = question.id || null; 
  const newOption = {
    option_text: "",
    is_correct: false,
    question: questionId,
  };

  this.editedQuiz.questions[questionIndex].options.push(newOption);

  this.$api.post('/options/', newOption)
    .then((response) => {
      const createdOption = response.data;
      question.options.push(createdOption);
    })
    .catch((error) => {
      console.error('Failed to create option:', error);
    });
}

,

    removeOption(questionIndex, optionIndex) {
  const option = this.editedQuiz.questions[questionIndex].options[optionIndex];
  const optionId = option.id;

  if (optionId) {
    this.$api.delete(`/options/${optionId}/`)
      .then(() => {
        this.editedQuiz.questions[questionIndex].options.splice(optionIndex, 1);
      })
      .catch((error) => {
        console.error('Failed to delete option:', error);
      });
  } else {
    this.editedQuiz.questions[questionIndex].options.splice(optionIndex, 1);
  }
},
saveQuiz() {
  const updatedQuiz = {
    id: this.editedQuiz.id,
    title: this.editedQuiz.title,
    date: this.editedQuiz.date,
    start_time: this.editedQuiz.start_time,
    end_time: this.editedQuiz.end_time,
  };

  this.$api
    .put(`/quizzes/${updatedQuiz.id}/`, updatedQuiz)
    .then(() => {
      const questionPromises = this.editedQuiz.questions.map((question) => {
        if (question.id) {
          return this.$api.put(`/questions/${question.id}/`, {
            question_text: question.question_text,
            question_type: question.question_type,
            mark: question.mark,
            quiz: updatedQuiz.id,
          });
        } else {
          return this.$api.post('/questions/', {
            question_text: question.question_text,
            question_type: question.question_type,
            mark: question.mark,
            quiz: updatedQuiz.id,
          }).then((response) => {
            const createdQuestion = response.data;
            this.editedQuiz.questions.push(createdQuestion);
          });
        }
      });

      Promise.all(questionPromises).then(() => {
        const optionPromises = this.editedQuiz.questions.flatMap((question) =>
          question.options.map((option) => {
            if (option.id) {
              return this.$api.put(`/options/${option.id}/`, option);
            } else {
              return this.$api.post('/options/', option).then((response) => {
                const createdOption = response.data;
                question.options.push(createdOption);
              });
            }
          })
        );

        Promise.all(optionPromises).then(() => {
          this.showEditDialog = false;
        });
      });
    });
}
,
    cancelEdit() {
      this.showEditDialog = false;
    },
  

  },
};
</script>
